<!--
 * @Author: 张博洋
 * @Date: 2021-07-15 16:28:55
 * @LastEditTime: 2021-07-15 16:29:36
 * @LastEditors: 张博洋
 * @Description: 
 * @FilePath: /hospital-h5/src/views/404/index.vue
 * 可以输入预定的版权声明、个性签名、空行等
-->
<template>
  <div class="bg-white middle">
    <div class="tc pd15">
      <!-- <img src="http://static.360buyimg.com/yao_static/lib/man/img/404.png"
           alt=""
           style="width:200px;" /> -->
      <p class="color-content f14 mt20">
        抱歉，您所访问的页面不存在，请确认您所输入的页面是否正确哦！<span class="color-error">{{ time }}s</span>后返回首页
      </p>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      time: 10,
      setTime: ""
    };
  },
  created () {
    this.timeOver();
  },
  destroyed () {
    if (this.setTime) {
      clearInterval(this.setTime);
    }
  },
  methods: {
    timeOver () {
      this.time = 10;
      if (this.setTime) {
        clearInterval(this.setTime);
      }
      this.setTime = setInterval(() => {
        this.time--;
        // console.log(11)
        if (this.time <= 0) {
          clearInterval(this.setTime);
          this.$router.replace("/");
        }
      }, 1000);
    }
  }
};
</script>

<style lang="scss" scoped></style>
